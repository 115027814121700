import {
	APPLICATION_EVENT,
	type AppPublish,
	type AppState,
	type AppSubscribe,
} from '@harbortouch/alexandria-utilities';
import { type Store, createStore } from './internal-store';
import { updateNavigationDisplay } from '../navigation';

type AppStateStore = AppState & AppStoreActions;

interface AppStoreActions {
	setNavigationOpen: (nextPosition: AppStateStore['isNavigationOpen']) => void;
	setNavigationVisible: (nextPosition: AppStateStore['isNavigationVisible']) => void;
	setLocationId: (nextPosition: AppStateStore['locationId']) => void;
	setDataOwnerId: (nextPosition: AppStateStore['dataOwnerId']) => void;
	setDataOwnerAndLocation: (nextPosition: {
		dataOwnerId: number | null;
		locationId: AppStateStore['locationId'];
	}) => void;
}
const DEBUG_LOGGER_KEY = 'STATE_LOGGER';
const logInfo = (...args: unknown[]) => {
	if (localStorage.getItem(DEBUG_LOGGER_KEY) !== null) {
		console.log(...args);
	}
};
const appStore: Store<AppStateStore> = createStore<AppStateStore>({
	locationId: null,
	dataOwnerId: null,
	isNavigationOpen: false,
	isNavigationVisible: false,
	setNavigationOpen: (navigationOpen) => appStore.setState({ isNavigationOpen: navigationOpen }),
	setNavigationVisible: (navigationVisible) => appStore.setState({ isNavigationVisible: navigationVisible }),
	setLocationId: (locationId) => appStore.setState({ locationId: locationId }),
	setDataOwnerId: (dataOwnerId) => appStore.setState({ dataOwnerId: dataOwnerId }),
	setDataOwnerAndLocation: ({ dataOwnerId, locationId }) =>
		appStore.setState({ dataOwnerId: dataOwnerId, locationId: locationId }),
});

export const initializeAppState = (publish: AppPublish, subscribe: AppSubscribe) => {
	const publishAppState = () => {
		const state = appStore.getState();
		if (state.locationId || state.dataOwnerId) {
			logInfo('APP-SHELL', 'PUBLISH on change', state);
			publish(APPLICATION_EVENT.APP_STATE, state);
		}
	};
	subscribe(APPLICATION_EVENT.IS_OPEN, (_, isSidebarOpen) => {
		logInfo('APP-SHELL', 'IS_OPEN', isSidebarOpen);
		appStore.getState().setNavigationVisible(true);
		appStore.getState().setNavigationOpen(isSidebarOpen);
	});
	subscribe(APPLICATION_EVENT.SIGNOUT, () => {
		appStore.getState().setNavigationVisible(false);
		appStore.getState().setNavigationOpen(false);
		appStore.getState().setDataOwnerId(null);
		appStore.getState().setLocationId(null);
	});
	subscribe(APPLICATION_EVENT.LOCATION, (_, data) => {
		logInfo('APP-SHELL', 'LOCATION EVENT', data);
		appStore.getState().setDataOwnerAndLocation({
			locationId: data.id,
			dataOwnerId: null,
		});
	});

	subscribe(APPLICATION_EVENT.CHANGE_DATA_OWNER, (_, data) => {
		logInfo('APP-SHELL', 'CHANGE_DATA_OWNER', data);
		appStore.getState().setDataOwnerAndLocation(data);
	});

	subscribe(APPLICATION_EVENT.APP_READY, () => {
		logInfo('APP-SHELL', 'APP_READY');
		publishAppState();
	});

	appStore.subscribe(() => {
		const state = appStore.getState();
		publishAppState();
		updateNavigationDisplay(state.isNavigationOpen, state.isNavigationVisible);
	});
};
