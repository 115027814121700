type Listener = () => void;

export interface Store<T> {
	getState: () => T;
	setState: (newState: Partial<T> | ((prevState: T) => Partial<T>)) => void;
	subscribe: (listener: Listener) => () => void;
}

export const createStore = <T>(initialState: T): Store<T> => {
	let state = initialState;
	const listeners = new Set<() => void>();

	const getState = () => state;

	const setState = (newState: Partial<T> | ((prevState: T) => Partial<T>)) => {
		const updatedState = {
			...state,
			...(typeof newState === 'function' ? newState(state) : newState),
		};

		// Check if state has actually changed
		if (JSON.stringify(state) !== JSON.stringify(updatedState)) {
			state = updatedState;
			listeners.forEach((listener) => listener());
		}
	};

	const subscribe = (listener: () => void) => {
		listeners.add(listener);
		return () => listeners.delete(listener);
	};

	return { getState, setState, subscribe };
};
