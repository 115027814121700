export const updateNavigationDisplay = (isNavigationOpen: boolean, isNavigationVisible: boolean) => {
	const appShell = document.getElementById('shift4-app-shell');
	const navigation = document.getElementById('shift4-navigation');
	if (appShell && navigation) {
		if (!isNavigationVisible) {
			navigation.style.opacity = '0';
			appShell.classList.remove('full-width-content');
			appShell.classList.remove('sidebar-content');
		} else {
			appShell.classList.add(isNavigationOpen ? 'sidebar-content' : 'full-width-content');
			appShell.classList.remove(isNavigationOpen ? 'full-width-content' : 'sidebar-content');
			navigation.style.opacity = isNavigationVisible ? '1' : '0';
		}
	}
};
