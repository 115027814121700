import {
	type ActivityFn,
	type RegisterApplicationConfig,
	addErrorHandler,
	getAppNames,
	getAppStatus,
	navigateToUrl,
	registerApplication,
	start,
} from 'single-spa';
import { loadEmberApp } from 'single-spa-ember';
import {
	type WithLoadFunction,
	constructApplications,
	constructLayoutEngine,
	constructRoutes,
} from 'single-spa-layout';
import PubSub from 'pubsub-js';
import { type AppPublish, type AppSubscribe, type AppUnsubscribe, getAuth } from '@harbortouch/alexandria-utilities';
import loadEmberUrls from './loadEmberUrls';
import '../assets/scss/layout.scss';
import microfrontendLayout from './microfrontend-layout.html';
import { checkTokenExpired, initializeLaunchDarkly } from './utils';
import { loadApp } from './asset-loader';
import initializeDatadog from './datadog-rum';
import { initializeAppState } from './state/app-state';

const publish: AppPublish = (event, data) => PubSub.publish(event, data);
const subscribe: AppSubscribe = (event, callback) => PubSub.subscribe(event, callback as any);
const unsubscribe: AppUnsubscribe = (event) => PubSub.unsubscribe(event);
type SpaApplications = (RegisterApplicationConfig & WithLoadFunction)[];
let applications: SpaApplications = [];

const mountApplications = async (applications: SpaApplications) => {
	const { appUrl, vendorUrl } = await loadEmberUrls();
	const authenInfo = getAuth()?.authenticated;
	if (authenInfo) {
		const client = await initializeLaunchDarkly(authenInfo);
		if (client) {
			initializeDatadog(client);
		}
	}

	applications.forEach((app) => {
		if (app.name === '@shift4/lh4') {
			// existing ember app has special app registration because it is hosted from '/'
			// it is rendered only when there are no other MFs active at current location
			registerApplication(
				'@shift4/lh4',
				() => loadEmberApp('lh4', appUrl, vendorUrl),
				(location) =>
					location.pathname === '/' ||
					!applications.find(
						(a) =>
							a.name !== '@shift4/lh4' &&
							a.name !== '@shift4/frontend-navigation' &&
							(a.activeWhen as []).find((i) => (i as ActivityFn)(location)),
					),
				{ publish, subscribe, unsubscribe },
			);
		} else {
			registerApplication({
				...app,
				customProps: () => ({
					publish,
					subscribe,
					unsubscribe,
				}),
			});
		}
	});
};

void (async () => {
	// check if token is expired on every route change
	window.addEventListener('single-spa:before-routing-event', () => {
		if (checkTokenExpired()) {
			navigateToUrl('/sign-in');
		}
	});

	addErrorHandler((err) => {
		console.error('Failed to load the App');
		console.error(`App name: ${err.appOrParcelName}`);
		console.error(`App status: ${getAppStatus(err.appOrParcelName)}`);
		console.error(`Error message: ${err.message}`);
	});

	const routes = constructRoutes(microfrontendLayout);
	applications = constructApplications({
		routes,
		loadApp,
	});

	const layoutEngine = constructLayoutEngine({ routes, applications });
	await mountApplications(applications);

	const isEmberAppActive =
		(applications.find((app) => app.name === '@shift4/lh4')?.activeWhen as any)[0](window.location) ?? false;

	const apps = getAppNames().filter((app) => app !== '@shift4/lh4');

	if (!isEmberAppActive && apps.length === 0) {
		// If there is no mounted applications and it's not ember app, navigate to sign-in page
		navigateToUrl('/sign-in');
	}
	layoutEngine.activate();
	initializeAppState(publish, subscribe);
	start();
})();
