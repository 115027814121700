import { datadogRum } from '@datadog/browser-rum';
import type { LDClient } from 'launchdarkly-js-client-sdk';

const initializeDatadog = (ldClient: LDClient) => {
	if (['staging', 'production'].includes(process.env.NODE_ENV!)) {
		datadogRum.init({
			applicationId: 'fc55d9cb-cebf-400c-806f-9c9412d4a8d7',
			clientToken: 'puba5d7dc3b663a9b89cab99d2b9affd2cc',
			site: 'datadoghq.com',
			service: 'lighthouse-frontend',
			env: process.env.NODE_ENV,
			version: '1.0.0',
			sessionSampleRate: 100,
			sessionReplaySampleRate: 1,
			trackViewsManually: true,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'mask-user-input',
			allowedTracingUrls: [(url) => url.startsWith(`${window.location.protocol}//${window.location.host}/api`)],
			beforeSend: () => ldClient.variation('lbm-track-usage-with-datadog'),
		});
	}
};

export default initializeDatadog;
